<div>
    <div class="background-img"></div>
    <div class="container">
        <div class="row blog">
            <div class="col-sm-offset-1 col-sm-8">
                <div class="proj-nav-wrapper">
                    <div class="proj-nav">
                        <a [routerLink]="['/blog/5-tips-for-a-great-living-room']" class="proj-nav-link proj-nav-prev arrow-wrapper">
                            <div class="arrow arrow-left">
                                <div class="arrow-line"></div>
                            </div>
                            <div class="proj-nav-name">5 Tips for a great living room</div>
                        </a>
                        <a [routerLink]="['/blog/5-tips-for-a-great-living-room']" class="proj-nav-link proj-nav-next arrow-wrapper">
                            <div class="arrow arrow-right">
                                <div class="arrow-line"></div>
                            </div>
                            <div class="proj-nav-name">5 Tips for a great living room</div>
                        </a>
                    </div>
                </div>

                <div class="card-blog">
                    <div class="blog-title-bar-container">
                        <div class="blog-title-bar"></div>
                    </div>
                    <h1>The Finishing School:<br> How to Choose the Woodwork Finish That is Just Right for You</h1>
                    <p>As a homeowner, you must have considered a variety of looks for your furniture. Choosing the right finish is puzzling – making the wrong choices compromises the entire design aesthetic, not to mention the additional cost of rework
                        in case the finish chosen is incorrect for the space or the application. </p>
                    <p>The question of “finishing” did not arise in the good old days when all woodwork was done using hardwood and finish simply meant polishing it. As plywood, MDF and even particle board increase in popularity and replace hardwood because
                        of cost, the type of finish becomes very important. </p>
                    <p>A finish is the kind of material and/ or polish that has been put on top of the base material which could be plywood or anything else. While design and aesthetics are essential in selecting the right finish, one should not forget about
                        durability and cost. </p>
                    <p>Veneer, Duco Coat, and PU (Polyurethane) Coat are commonly used premium finishes, while laminate and acrylic fall in the economic finish category. A high-gloss premium-look can be delivered using acrylic, laminate, Duco paint or PU
                        coating – however, the usage of one over the other depends on application (e.g. kitchen or wardrobe), the design (whether the surface is plane or curved), availability of the colour that fits in with the overall scheme and the
                        level of maintenance that you, as a homeowner can provide to your furniture. </p>


                    <h3>Veneer: Simulate the most exquisite natural wood-finish look with the best substitute </h3>
                    <p>
                        Veneer is natural hardwood bark of 3-8 mm thickness obtained by peeling them off tree-trunks. Veneer is more readily available than solid wood or exotic hardwood which are scarce and significantly more expensive. If wooden textures are something you love
                        on your furniture but can’t use teakwood or sheesham, a veneer finish is the next best thing. As plywood is used to make furniture instead of solid wood, veneer helps achieve the natural wood look with a sophisticated woodgrain
                        finish. Natural grain surface of veneers with growth-rings depend upon the slicing-angle. Two types of veneers are available – Crown Cut, and Rift Cut, both giving a distinctive type of grain-finish. Only then comes the layout
                        and furniture plan that takes the homeowners’ lifestyle into account.
                    </p>
                    <p>Veneer surfaces do need finishing and a coat of polish. As veneers are thin strips of natural wood, that are stuck on top of plywood followed by sanding and polishing – expertise is required to bring out the charm in veneer. Types
                        of polishes can vary from melamine (little gloss) to PU (matte to glossy) and polyester (high gloss). </p>
                    <p>Compared to other wood finishes, veneer is stable and durable, with less chance of splitting or cracking. Similar in look to exotic hardwood, you can use veneer to add natural splendour to your living room, dining room, kitchen, bedroom,
                        study and family room furniture.
                    </p>

                    <h3>Veneer at a Glance
                    </h3>
                    <ul>
                        <li>A natural material giving a richer and more varied look to your furniture </li>
                        <li>Priced higher than laminate alternatives as more effort goes into crafting a veneer top
                        </li>
                        <li>Being softer, veneer is more susceptible to scratches and dents compared to laminates
                        </li>
                    </ul>


                    <h3>Laminate: Get the glamour with an economic finish </h3>
                    <p>Laminate is one of the most commonly used finishing materials due to its ease of application on plywood. Due to its plastic resin content, laminate is more water-resistant than veneer and is recommended for usage in kitchens, bathrooms
                        and utility area furniture.
                    </p>
                    <p>
                        However, since laminate is manmade, it lacks the natural look and woodgrain texture of veneer. Unlike veneer which can be worked with to create soft edges, laminated edges are hard. Laminate is difficult to use for ledges made of plywood or MDF of non-standard
                        thickness as this leads to an improper finish. Being brittle and fragile, laminates can chip easily – once chipped, it is not possible to repair the surface.
                    </p>

                    <h3>Laminate at a Glance
                    </h3>
                    <ul>
                        <li>Laminate is stuck using regular adhesive on ply – no post application processing required
                        </li>
                        <li>Easy to use, waterproof, gives an even finish, and can withstand rough use while retaining the look for many years
                        </li>
                    </ul>


                    <h3>Acrylic: For glass-like brilliantly finished surfaces </h3>
                    <p>Acrylics are pre-laminated 1 mm plastic-sheets or readymade boards manufactured using one or more derivatives of acrylic acid. PMMA (polymethyl methacrylate acrylic) is a widely used acrylic variant due to its exceptional weatherability,
                        strength, clarity and versatility. </p>
                    <p>At half the weight of glass, acrylic-sheets deliver glass-like finish qualities – such as clarity, brilliance, transparency and translucence. Acrylic polymers are available at varying levels of light transmission and release capabilities
                        (transparent-to-opaque), can be tinted or coloured, and have mirrored to matte-gloss to high-gloss finishes. </p>
                    <p>Due to their variety of finishes, good light reflective capacity and water-resistance, acrylic sheets can be used for plane or straight-line applications such as kitchen and wardrobe shutters and applied on external or exposed surfaces.
                        However, acrylic is more scratch-prone compared to glass. </p>

                    <h3>Acrylic at a Glance
                    </h3>
                    <ul>
                        <li>Like laminate, acrylic sheets pasted on plywood/ MDF/ HDF </li>
                        <li>Finish is unblemished and there is no waviness is high gloss finishes</li>
                    </ul>

                    <h3>
                        Duco Paint: Most versatile method of achieving a high-gloss finish and luxurious look
                    </h3>
                    <p>Duco is an automotive lacquer paint-based finish that has now started being done on wood and is best-suited for plane MDF and smoothened plywood. Duco pain finish hides natural woodgrains covering them in a layer of opaque coat. When
                        used correctly and done well, Duco paint gives a luxurious and expensive look to ordinary furniture and a premium look to home and interiors. </p>
                    <p>Duco-paints are available in a variety of colour options, but no texture or grain finishes. Applied on furniture by spraying, Duco requires extensive surface preparation and finishing. However, Duco-painted furniture is not scratch
                        resistant – applied MDF-surfaces are prone to chipping and wear. </p>

                    <h3>Duco at a Glance
                    </h3>
                    <ul>
                        <li>Wide range of colour and glossy-to-matte options available give Duco-finished furniture a modern, contemporary appeal
                        </li>
                        <li>Preferred for a luxury look for decorative furniture – not recommended for high-traffic, wet areas or areas subject to heat
                        </li>
                    </ul>

                    <h3>PU (Polyurethane) Coat: Durable finish for a lustrous look </h3>
                    <p>Available as water or oil-based varieties, PU varnish is a highly durable finish that is also easy to apply. Essentially plastic in a liquid form till it dries up, PU is resistant to heat, chemicals, regular wear and tear such as scratches
                        and abrasions. When applied well, oil-based PU coat results in a lustrous finish that deepens the colour of wood while not penetrating it. A synthetic resin, PU is a desirable choice for varnish because it is transparent, water
                        and fungus resistant and dries more quickly compared to other varnishes. </p>
                    <p>PU coatings are available as both glossy and matte finishes, with a few stain and opaque options as well – shaded and textured options are not available. If applied incorrectly, PU coats can cause wood surfaces appear drab like plastic.
                        Also, PU coating is not UV-resistant and cheaper varieties can get tinged yellow over time. Once furniture is coated with PU, it is difficult to add other types of finishes as adhesion is limited. </p>
                    <p>PU resin varnish is used in finishing floors, cabinets decks, loose furniture such as wooden chairs, to refurbish old furniture and protect new ones with a shiny coating. It is best used for display-cases and cabinetry that are showpieces
                        and have limited functional use. As PU coating is water-proof and easy to clean, this can be used on kitchen woodwork as well. </p>


                    <h3>PU at a Glance
                    </h3>
                    <ul>
                        <li>A coat put on top of woodwork for protection and to get a lustrous finish</li>
                        <li>Highly versatile – achieve a completely matte or super-glossy look and anything in between</li>
                    </ul>

                    <p>Helping homeowners select appropriate furniture and woodwork finishes is the job of good designers. NVT Design Studio believes that good design is creates spaces of value by making the right design-choices. </p>

                    <h3>The Finishing Cheat Sheet:</h3>
                    <p>Here is ready-reckoner to help you select the best-finish for your furniture considering the look that you are after and usage: </p>
                    <div class="table-contain">
                        <table class="w3-table-all">
                            <tr class="header">
                                <th>KEY FACTORS TO KEEP IN MIND</th>
                                <th>VENEER</th>
                                <th class="w3-center">LAMINATE</th>
                                <th class="w3-center">DUCO PAINT</th>
                                <th class="w3-center">ACRYLIC SHEET</th>
                                <th class="w3-center">PU COAT</th>
                            </tr>
                            <tr>
                                <td>Look/ Appearance</td>
                                <td>Premium: natural woodgrain, rich & varied look</td>
                                <td class="w3-center">Economy – manufactured surface, lacks natural variations </td>
                                <td class="w3-center">Premium – when applied well, can make ordinary furniture look luxurious and expensive </td>
                                <td class="w3-center">Economy – achieve glass-like finish that is clear and brilliant </td>
                                <td class="w3-center">Premium – lustrous finish </td>
                            </tr>
                            <tr>
                                <td>Colour/ varieties available</td>
                                <td>Available in natural textures – only gloss or matt or a combination polishing possible</td>
                                <td class="w3-center">Available in various shades, colours, prints & patterns, textures, finishes (gloss to matte) </td>
                                <td class="w3-center">Available in a variety of colour glossy-to-matte options – no texture or grain-finish options available </td>
                                <td class="w3-center">Limited range Available as tinted, colour, mirrored, opaque, textured, matte to high-gloss finishes </td>
                                <td class="w3-center">Available in both glossy and matte options – few stain and opaque options </td>
                            </tr>
                            <tr>
                                <td>Plane or curved surface suitability</td>
                                <td>Both</td>
                                <td class="w3-center">Plane</td>
                                <td class="w3-center">Works great on angular, curved surfaces with minimal joints</td>
                                <td class="w3-center">Plane </td>
                                <td class="w3-center">Both</td>
                            </tr>
                            <tr>
                                <td>Ease of applying the finish</td>
                                <td class="w3-center">Expertise required – handle woodgrain, sanding & polishing</td>
                                <td class="w3-center">Easy Edges need to carefully done to look seamless </td>
                                <td class="w3-center">Expertise required – to prepare surface before spay-application </td>
                                <td class="w3-center">Easy – acrylic sheets are factory-pasted </td>
                                <td class="w3-center">Easy to apply but expertise required to polish and do justice to the finish </td>
                            </tr>
                            <tr>
                                <td>Durability: scratch or abrasion resistance</td>
                                <td>Stable, less chance of splitting or cracking </td>
                                <td class="w3-center">Prone to chipping esp. for 0.8 mm or less </td>
                                <td class="w3-center">Not scratch resistant </td>
                                <td class="w3-center">More scratch-prone than glass</td>
                                <td class="w3-center">Highly durable: scratch and abrasion resistant </td>
                            </tr>
                            <tr>
                                <td>Effect of wear & tear</td>
                                <td>Susceptible to scratches and dents – easily fixed</td>
                                <td class="w3-center">Good for furniture used heavily </td>
                                <td class="w3-center">MDF-surfaces prone to chipping and wear </td>
                                <td class="w3-center">Limited – but catches finger marks </td>
                                <td class="w3-center">Resistant to wear and tear</td>
                            </tr>
                            <tr>
                                <td>Water resistance</td>
                                <td>Limited</td>
                                <td class="w3-center">Water resistant and stain-free</td>
                                <td class="w3-center">Limited</td>
                                <td class="w3-center">High</td>
                                <td class="w3-center">Water and fungus resistant </td>
                            </tr>
                            <tr>
                                <td>Furniture types best suited and recommended for </td>
                                <td>Versatile but for static furniture that are statement pieces of focal points: use for living, dining, kitchen (dry area), bedroom, study and family areas</td>
                                <td class="w3-center">Kitchen, bathroom and utility areas where there is heavy water-use <br> High-gloss laminates can be used for wardrove shutters and wall panelling </td>
                                <td>
                                    Preferred finish for luxury look: apply this finish to show-pieces and décor
                                </td>
                                <td>Kitchen and wardrobe shutters <br> Kitchen and external/ exposed surfaces </td>

                                <td>
                                    Rigid, non-moving furniture, not heavy use Use for finishing floors, cabinets, decks, loose furniture such as wooden chairs
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
import {Component, ElementRef, ViewChild,OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
import { Title, Meta } from '@angular/platform-browser'


import { ModalService } from '../../common-module/video.service';

declare var $: any;
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

 project:any;
 slug:string;
 show = false;

 contentHeight: any;

 @ViewChild('getHeight',{read: ElementRef, static:false}) getHeight: ElementRef;

  constructor(
	private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private modalService: ModalService,
    private route: Router,
    private titleService: Title,
    private metaService: Meta,
    private elementRef:ElementRef,
  	) {
      this.activeRoute.params.subscribe(routeParams  => {
        this.slug = routeParams.slug;
          this.getProject(this.slug);
        });
     }



  ngOnInit() {
  }
  
 
  addClass(){
      
  }

   getProject(slug) {
     var self = this;
    this.apiService.getProjectDetail(slug).subscribe(
        list => {
          this.project = list;

          setTimeout(function(){
              self.contentHeight = $("#height").innerHeight();
          }, 0);
        },
      );
  }

  clickShow(event){
    this.show = !this.show;
    this.contentHeight = 850;
    if(event == 'less'){
      this.contentHeight =  $("#height").innerHeight();
    }
  }

  openModal(id: string) {
      this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }

}


import { Component,OnInit,Input, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NgwWowService } from "ngx-wow";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { ApiService } from './core/services/api.service';
declare var $: any;


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent implements OnInit {

  getTouch: FormGroup;
  loading = false;
  homeBtn:boolean;
  hidden = true;
  error = true;
  submitted = false;
  isProject = true;

  toggle:boolean = true;
  projectToggle:boolean = true;
  projects:any;
  windowHeight:string;
  count: number = 400;
 

  title = "NVT Design Studio";
  
  @Input() home: HomeComponent;

  constructor(private router: Router, private wowService: NgwWowService, private formBuilder: FormBuilder,    private apiService: ApiService,) {
    
    this.wowService.init();
  
    this.router.events.subscribe((event: any) => {
      window.scrollTo(0, 0);
      $("body").removeAttr("class");
      this.windowHeight = $(window).height() + 'px';
      if (event instanceof NavigationEnd) {
       
        if (this.router.url == "/home") {
          this.isProject = false;
          this.homeBtn = true;
           
            $("body").addClass("home");
            $("#header").css('display', 'none');
            // $('#outer-wrapper').removeClass('project-nav');
        } else {
          this.isProject = true;
          this.homeBtn = false;
            $("body").removeClass("home");
            $("#header").css('display', 'block');
            // $('#outer-wrapper').removeClass('project-nav');
            $('body').css('position', 'relative');
            // $('.page-wrapper').css('position', 'relative');
        }  
        if (this.router.url == "/about-us") {
          $('.outer-wrapper').css({'position': 'static'});
          $('body').css({'overflow': 'hidden'});
        }else{
          $('.outer-wrapper').css({'position': 'relative'});
          $('body').css({'overflow': 'auto'});
        }
      }
    });

  
  }


  ngOnInit() {

    this.getTouch = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      message: ["", [Validators.required]],
    });
  }
    // convenience getter for easy access to form fields
    get f() { return this.getTouch.controls; }

    onSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      const scriptURL = 'https://script.google.com/macros/s/AKfycbzecUsUQmC_locybXA5M4oZW45Y4_B0ZBtki19fYZ4K1NIFwMeIILvjNZIhRtggR9Dk/exec'
      const form = document.forms['submit-to-google-sheet']
      
      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        .then(response => {
            //console.log('Success!', response))
            $('#form-contact').trigger("reset");
            //alert('Form Submitted Successfully');
        })
          if (this.getTouch.invalid) {
              return;
          }
  
        this.error = true;

        const formData = new FormData();
        formData.append('name', this.getTouch.get('name').value);
        formData.append('phone', this.getTouch.get('phone').value);
        formData.append('email', this.getTouch.get('email').value);
        formData.append('message', this.getTouch.get('message').value);
    
        this.loading = true;

        this.apiService.contact(formData).subscribe(
          result => {
              this.getTouch.reset();
              this.submitted = false;
              this.hidden = false;
              this.loading = false;
               setTimeout (() => {
                  this.hidden = true;
                }, 4000);
          },
          error => {
            this.loading = false;
           this.error = false;
           setTimeout (() => {
                  this.error = true;
                }, 4000);
          }
        );
  
      
    }

  openProjectToggle(){
    if(this.projectToggle == true){
      $('.page-wrapper').css({'position':'fixed','z-index':'14','background': '#fff'});
      $('#outer-wrapper').removeClass('project-nav');
      this.toggle = true;
      this.getProjects();
         $('#outer-wrapper').addClass('show-nav');
         
         this.projectToggle = false;
    }else{
      $('.page-wrapper').css({'position':'relative','z-index':'12','background': '#fff'});
       $('#outer-wrapper').removeClass('show-nav');
       this.projectToggle = true;
    }
  }

    getProjects() {
      this.apiService.getAllProject().subscribe(
          list => {
            this.projects = list;
          },
        );
    }
  
    openToggle(){
      if(this.toggle == true){
        $('.page-wrapper').css({'position':'fixed','z-index':'14','background': '#fff'});
        $('#outer-wrapper').removeClass('show-nav');
           $('#outer-wrapper').addClass('project-nav');
           this.toggle = false;
           this.projectToggle = true;
      }else{
        $('.page-wrapper').css({'position':'relative','z-index':'12','background': '#fff'});
         $('#outer-wrapper').removeClass('project-nav');
         this.toggle = true;
      }
    }
  
    closeProject(){
      //  $('#outer-wrapper').removeClass('project-nav');
      //    this.toggle = true;
    } 
  
    menuProject(){
      //   $('#outer-wrapper').removeClass('show-nav');
      //  this.projectToggle = true;
    }

    scrollDown(){
      this.count = this.count + 400;
      $('html').animate({scrollTop: this.count}, 1000);
    }


    clickScroll() {
     
      this.apiService.homeScroll();
    }


  
}

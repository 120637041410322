import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgwWowModule } from 'ngx-wow';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CommonModuleModule } from './common-module/common-module.module';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeComponent } from './pages/home/home.component';
import { BlogComponent } from './pages/blog/blog.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { OurProjectComponent } from './pages/our-Project/our-Project.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BlogSecondComponent } from './pages/blog-second/blog-second.component';


// Service file
import { HttpClientHelperService } from './core/Http/http-client-helper.service';
import { CitGlobalContantsService } from './core/globalUrl/cit-global-contants.service';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';

import { GalleryModule } from  '@ngx-gallery/core';
import { LightboxModule } from  '@ngx-gallery/lightbox';
import { GallerizeModule } from  '@ngx-gallery/gallerize';

import { AnimatedComponent } from "./pages/animated/animated.component";



@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    AboutUsComponent,
    HomeComponent,
    BlogComponent,
    HowItWorksComponent,
    ProjectDetailsComponent,
    OurProjectComponent,
    AnimatedComponent,
    PageNotFoundComponent,
    BlogSecondComponent,
    
  ],
  imports: [

    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
     GalleryModule.withConfig({
      loadingMode: 'indeterminate'
    }),
    LightboxModule,
    GallerizeModule,
    CommonModuleModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFullpageModule,
    NgwWowModule,
    AppRoutingModule
  ],
  providers: [
    CitGlobalContantsService,
    HttpClientHelperService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { VideoPopupComponent } from './video-popup/video-popup.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [HeaderComponent, FooterComponent, VideoPopupComponent],
  exports: [
    HeaderComponent,
    FooterComponent,
    VideoPopupComponent,
  ],
  providers: []
})
export class CommonModuleModule { }

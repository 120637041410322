

import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
declare var $: any;

@Component({
   selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

 projects:any;
 form: FormGroup;
   loading = false;
   hidden = true;
   error = true;
   submitted = false;

  constructor(
	  private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private route: Router
  	) {}

  ngOnInit() {
    $("body").removeAttr("class");
    $("body").addClass("contact");

  $("iframe").contents().find(".place-card-large").css('top', '190px');

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      message: ["", [Validators.required]],
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

   onSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      //alert('Submitted Successfully');
      const scriptURL = 'https://script.google.com/macros/s/AKfycbzecUsUQmC_locybXA5M4oZW45Y4_B0ZBtki19fYZ4K1NIFwMeIILvjNZIhRtggR9Dk/exec'
      const form = document.forms['submit-to-google-sheet']
      
      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        .then(response => {
            //console.log('Success!', response))
            $('#form-contact').trigger("reset");
            //alert('Form Submitted Successfully');
        })
      if (this.form.invalid) {
          return;
      }
      this.error = true;


    const formData = new FormData();
    formData.append('name', this.form.get('name').value);
    formData.append('phone', this.form.get('phone').value);
    formData.append('email', this.form.get('email').value);
    formData.append('message', this.form.get('message').value);

    this.loading = true;
    this.apiService.contact(formData).subscribe(
        result => {
            this.form.reset();
            this.submitted = false;
            this.hidden = false;
            this.loading = false;
             setTimeout (() => {
                this.hidden = true;
              }, 4000);
        },
        error => {
          this.loading = false;
         this.error = false;
         setTimeout (() => {
                this.error = true;
              }, 4000);
        }
      );
  }
  

}



<div>
    <div class="background-img"></div>
    <div class="block">
        <div class="container">
            <div class="row">
                <div class="col-md-offset-1 col-md-10">
                    <div class="header-content">
                        <h1><strong>We will be happy to create your story</strong></h1>
                        <p class="title">Our designers, artists and carpenters are the best in the industry. They will give life to a home that is a reflection of you and will always be more than a home. A beautiful story. Fill up the form below and we will set up a quick
                            call / chat for you with our designer.
                        </p>
                    </div>
                </div>
                <!--end col-md-8-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end block-->
    <div class="container">
        <div class="row contactbg">
            <div class="col-md-offset-3 col-md-6">
                <form class="form-horizontal" [formGroup]="form" name="submit-to-google-sheet" id="form-contact" (ngSubmit)="onSubmit()">
                    <input type="hidden" name="DataSource" id="" value="Website">
                    <input type="hidden" name="url" id="" value="https://nvtdesignstudio.com/contact-us">
                    <div class="alert alert-success success" data-alert="alert" [hidden]="hidden">Submitted Successfully</div>
                    <div class="error error-success error" data-alert="alert" [hidden]="error">Please Try Again</div>
                    <div class="form-group">
                        <label class="control-label col-sm-4" for="name">Your Name</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="name" name="Name" formControlName="name" placeholder="Name" required="">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Full Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-4" for="number">Phone Number</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" id="number" name="Phone" formControlName="phone" maxlength="10" placeholder="Phone Number" required="">
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-4" for="email">Your Email</label>
                        <div class="col-sm-8">
                            <input type="email" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" name="Email" formControlName="email" placeholder="Email" required="">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-4" for="msg">Your Message</label>
                        <div class="col-sm-8">
                            <textarea class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" id="msg" name="Message" rows="8" formControlName="message" required="" placeholder="Message"></textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-0">
                        <div class="col-sm-offset-4 col-sm-8 p-0">
                            <div class="pull-right">
                                <button type="submit" [disabled]="loading" class="btn sales-btn">Contact us</button>
                            </div>
                        </div>
                    </div>
                </form>
                <!--end form-->
            </div>
            <div class="col-md-3">
            </div>
            <!--end col-md-8-->
        </div>
        <!--end row-->
        <div class="row contactbg">
            <div class="col-md-offset-3 col-md-6 cta-contain">
                <div class="bottom-container">
                    <div class="cta">
                        <div class="icon-column">
                            <img src="../../../assets/icon/communication.svg" />
                        </div>
                        <div class="copy-column">
                            <h4 class="cta-headline">Email ID</h4>
                            <p class="cta-copy"><span class="non-link"><a style="color: white;" href="mailto:designstudio@nvtqualitygroup.com">designstudio@nvtqualitygroup.com</a></span> </p>
                        </div>
                    </div>
                    <div class="cta">
                        <div class="icon-column">
                            <img src="../../../assets/icon/support.svg" />
                        </div>
                        <div class="copy-column">
                            <h4 class="cta-headline">Contact Us</h4>
                            <p class="cta-copy"><span class="non-link"><a style="color: white;" href="tel:+917406955663">+91 - 7406955663</a></span></p>
                        </div>
                    </div>
                </div>
                <!--end form-->
            </div>
            <div class="col-md-3">
            </div>
            <!--end col-md-8-->
        </div>
    </div>
</div>


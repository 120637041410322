<div class="block how-its-work">
    <div class="container">
        <div class="row zero">
            <div class="how-header">
                <h1>
                    Your dream home in 4 steps!
                </h1>
                <h5>Looking to design your home interiors? Here's how you can get started.</h5>
            </div>
            <div class="col-sm-1 col-md-3"></div>
            <div class="col-sm-5 col-md-5 image-section">
                <figure class="first-Section">
                    <img src="https://res.cloudinary.com/gurutu/image/upload/s--w6TxXRbj--/c_thumb,h_284,w_360/v1585163235/NVT/Projects/393ecfc3-bbd3-46dd-8383-455306380e8f/Kitchen_view02DinningArea_qtzzxo.jpg" class="wow fadeInLeft" data-wow-delay="0.3s" />
                </figure>
            </div>

            <div class="col-sm-5 col-md-4 list-section">
                <ul>
                    <li class="active">
                        <div class="carousel-title-item">
                            <div class="active-title"></div>
                        </div>
                        <h2>Ask For A Free Quote</h2>
                        <p>Give us your requirements in brief and we will set up a scoping meeting where we understand your requirements better.</p>
                    </li>
                    <li class="active">
                        <!-- <div class="milestone-circle"></div> -->
                        <h2>Scoping Meeting</h2>
                        <p>We get into the details of your requirement with a structured questionnaire. We understand your taste and preferences and get back to you with an estimate.</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row first">
            <div class="col-sm-1 col-md-3"></div>
            <div class="col-sm-5 col-md-4 list-section">
                <ul>
                    <li class="active">
                        <div class="carousel-title-item">
                            <div class="active-title"></div>
                        </div>
                        <h2>Meet Your Design Team</h2>
                        <p>We match your tastes and preferences to an Interior Designer who will work on your project. We also appoint a Site Manager and Supervisor who will form part of the team that executes your home’s interior design.
                        </p>
                    </li>
                    <li class="active">
                        <!-- <div class="milestone-circle"></div> -->
                        <h2>Finalization Of Design</h2>
                        <p>Our design team creates a 3D render of your home with the exact specifications created to match your tastes and preferences. This results in the creation of a final quotation that considers the materials and colours you select.
                            With this, we sign off on the project.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-5 col-md-5 image-section">
                <figure>
                    <img src="https://res.cloudinary.com/gurutu/image/upload/s--W3-_Fjj1--/c_thumb,h_284,w_360/v1587445277/NVT/generic/16.LS-V-58-LIIVNG.jpg" class="wow fadeInRight" data-wow-delay="0.3s" />
                </figure>
            </div>

        </div>


        <div class="row second">
            <div class="col-sm-1 col-md-3"></div>
            <div class="col-sm-5 col-md-5 image-section">
                <figure>
                    <img src="https://res.cloudinary.com/gurutu/image/upload/s--Kz3tHoEs--/c_thumb,h_284,w_360/v1586675823/NVT/NVT-Design-Studio.jpg" class="wow fadeInLeft" data-wow-delay="0.3s" />
                </figure>
            </div>
            <div class="col-sm-5 col-md-4 list-section">
                <ul>
                    <li class="active">
                        <div class="carousel-title-item">
                            <div class="active-title"></div>
                        </div>
                        <h2>Work Summary Creation</h2>
                        <p>We create a process flow of tasks that need to be executed, spanning electrical, civil, plumbing, fabrication, carpentry, painting work, etc that need to be done in sequence for best results. We create a work stage and timeline
                            sheet that you can track online.</p>
                    </li>
                    <li class="active">
                        <!-- <div class="milestone-circle"></div> -->
                        <h2>Work Progress</h2>
                        <p>Our team plans, supervises, and executes everything from furniture installation, civil work to wall treatments keeping in mind the sequence and workflow. Regular status updates will be shared with you online.
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row third">
            <div class="col-sm-1 col-md-3"></div>
            <div class="col-sm-5 col-md-4 list-section">
                <ul>
                    <li class="active">
                        <div class="carousel-title-item">
                            <div class="active-title"></div>
                        </div>
                        <h2>Ongoing Review And Escalation Matrix</h2>
                        <p>Our work schedule comes with a tracker with a green-amber-red status indicator that indicates on-time progress. The management team is available for any escalations that may be required.</p>
                    </li>
                    <li class="active">
                        <h2>Handover Your Home</h2>
                        <p>Your home is ready for you to move in and create your own memories. Our teams are available for any last-minute fitment changes that are required post you moving in.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-5 col-md-5 image-section">
                <figure>
                    <img src="https://res.cloudinary.com/gurutu/image/upload/s--EX_ofVrM--/c_thumb,h_284,w_360/v1562311557/NVT/ceiling-fan-contemporary-fireplace-2015490.jpg" class="wow fadeInRight" data-wow-delay="0.3s" />
                </figure>
            </div>

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</div>
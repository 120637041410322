<div>

    <div class="block">
        <div class="">
            <div class="row about">
                <div id="myCarousel" class="carousel slide vertical" data-wrap="false" data-interval="false">
                    <!-- Indicators -->
                    <div class="carousel-inner">
                        <div class="item active" id="1">
                            <!-- Set the first background image using inline CSS below. -->
                            <div class="fill insetborder " style="background-image:url('/assets/img/about/property-01.jpg');"></div>

                            <!-- Story for Slides start -->
                            <div id="proj-infos-wrapper" [ngStyle]="styleObject()" [ngClass]="{closed: isClosed, open: !isClosed}">
                                <!-- Open -->
                                <div id="proj-infos-open" class="icon-wrapper-hover" (click)="openPopUp()">
                                    <div class="icon-wrapper icon-wrapper-right">
                                        <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                    </div>
                                    <div class="proj-infos-open-icon">i</div>
                                </div>
                                <div id="proj-infos" class="proj-infos">
                                    <!-- Nav -->
                                    <div class="proj-nav-wrapper">
                                        <div class="proj-nav">

                                            <a class="proj-nav-link proj-nav-next arrow-wrapper" data-target="#myCarousel" data-slide="next" style="float: right;">
                                                <div class="arrow arrow-right">
                                                    <div class="arrow-line"></div>
                                                </div>
                                                <div class="proj-nav-name">THE TEAM</div>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Close -->
                                    <div id="proj-infos-close" class="icon-wrapper-hover">
                                        <div class="icon-wrapper icon-wrapper-right" (click)="openPopUp()">
                                            <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                        </div>
                                    </div>
                                    <!-- Text -->
                                    <div class="proj-infos-txt">
                                        <div class="proj-infos-category">The Story</div>
                                        <div class="proj-infos-title">
                                            <h4 class="proj-infos-title-l1">Every home tells a story, but we help you tell your story better.</h4>
                                            <div class="proj-infos-title-bar-container">
                                                <div class="proj-infos-title-bar"></div>
                                            </div>
                                        </div>
                                        <div class="proj-infos-scrollable-wrapper" [ngStyle]="scrollObject()">
                                            <div class="proj-infos-scrollable-container">
                                                <div class="proj-infos-scrollable">
                                                    <div class="proj-infos-desc">
                                                        <p>At NVT Design Studio we bring together design aesthetics and contemporary functional concepts to create your dream home.</p>
                                                    </div>
                                                    <div class="proj-infos-features">
                                                        <div class="proj-infos-feature">
                                                            <div class="proj-infos-features-l1">To do this, we tell your story, through your home.</div>
                                                            <div class="proj-infos-features-l2">
                                                                <p>NVT Design Studio was founded by Shikha Garg who brought a keen international design sensibility with the delivery of superior value in execution towards interior projects. Not only does
                                                                    today’s discerning home buyer seek work of an international, she is also keen on retaining the Indian aesthetic and design sensibilities. Shikha’s designs deliver on both fronts, as the
                                                                    long list of satisfied clients will testify.&nbsp; &nbsp;</p>

                                                                <p>What sets NVT Design Studio apart is our talent. With an in-house pool of highly trained, skilled and experience talent, we are well poised to deliver on what your dream home must look like.
                                                                    This talent pool ensures that we bring alive our shared vision alive with superior execution as well.</p>
                                                            </div>
                                                        </div>
                                                        <div class="proj-infos-feature">
                                                            <div class="proj-infos-features-l1">NVT Design Studio employs an in-house team of experienced professionals: </div>
                                                            <div class="proj-infos-features-l2">
                                                                <p> 3D designers | 2D designers |Carpenters | Electricians | False Roofing Team</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Story for Slides end -->
                        </div>
                        <div class="item" id="2">
                            <!-- Set the second background image using inline CSS below. -->
                            <div class="fill insetborder" style="background-image:url('/assets/img/about/property-02.jpg');"></div>
                            <!-- Story for Slides start -->
                            <div id="proj-infos-wrapper" [ngStyle]="styleObject()" [ngClass]="{closed: isClosed, open: !isClosed}">
                                <!-- Open -->
                                <div id="proj-infos-open" class="icon-wrapper-hover" (click)="openPopUp()">
                                    <div class="icon-wrapper icon-wrapper-right">
                                        <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                    </div>
                                    <div class="proj-infos-open-icon">i</div>
                                </div>
                                <div id="proj-infos" class="proj-infos">
                                    <!-- Nav -->
                                    <div class="proj-nav-wrapper">
                                        <div class="proj-nav">
                                            <a href="" class="proj-nav-link proj-nav-prev arrow-wrapper" data-target="#myCarousel" data-slide="prev">
                                                <div class="arrow arrow-left">
                                                    <div class="arrow-line"></div>
                                                </div>
                                                <div class="proj-nav-name">THE STORY</div>
                                            </a>
                                            <a class="proj-nav-link proj-nav-next arrow-wrapper" data-target="#myCarousel" data-slide="next">
                                                <div class="arrow arrow-right">
                                                    <div class="arrow-line"></div>
                                                </div>
                                                <div class="proj-nav-name">THE AWARDS</div>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Close -->
                                    <div id="proj-infos-close" class="icon-wrapper-hover">
                                        <div class="icon-wrapper icon-wrapper-right" (click)="openPopUp()">
                                            <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                        </div>
                                    </div>
                                    <!-- Text -->
                                    <div class="proj-infos-txt">
                                        <div class="proj-infos-category">The Founder</div>
                                        <div class="proj-infos-title">
                                            <h4 class="proj-infos-title-l1">Meet The Founder</h4>
                                            <div class="proj-infos-title-bar-container">
                                                <div class="proj-infos-title-bar"></div>
                                            </div>
                                        </div>
                                        <div class="proj-infos-scrollable-wrapper" [ngStyle]="scrollObjectFounder()">
                                            <div class="proj-infos-scrollable-container">
                                                <div class="proj-infos-scrollable">
                                                    <div class="proj-infos-features team-section">
                                                        <div class="proj-infos-feature">
                                                            <figure>
                                                                <img src="assets/img/shikha.png" width="172" height="150" alt="" style="object-fit: contain;">
                                                            </figure>
                                                            <div class="proj-infos-features-l1">MRS. SHIKHA GARG : </div>
                                                            <div class="proj-infos-features-l2">
                                                                <p>FOUNDER</p>
                                                                <p>NVT Design Studio was founded by Shikha Garg who brought a keen international design sensibility with the delivery of superior value in execution towards interior projects. Not only does
                                                                    today's discerning home buyer seek work of an international, but she is also keen on retaining the Indian aesthetic and design sensibilities. Shikha's designs deliver on both fronts,
                                                                    as the long list of satisfied clients will testify</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Story for Slides end -->
                        </div>
                        <div class="item" id="3">
                            <!-- Set the third background image using inline CSS below. -->
                            <div class="fill insetborder" style="background-image:url('/assets/img/about/property-03.jpg');"></div>
                            <!-- Story for Slides start -->
                            <div id="proj-infos-wrapper" [ngStyle]="styleObject()" [ngClass]="{closed: isClosed, open: !isClosed}">
                                <!-- Open -->
                                <div id="proj-infos-open" class="icon-wrapper-hover" (click)="openPopUp()">
                                    <div class="icon-wrapper icon-wrapper-right">
                                        <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                    </div>
                                    <div class="proj-infos-open-icon">i</div>
                                </div>
                                <div id="proj-infos" class="proj-infos">
                                    <!-- Nav -->
                                    <div class="proj-nav-wrapper">
                                        <div class="proj-nav">
                                            <a href="" style="float: left;" class="proj-nav-link proj-nav-prev arrow-wrapper" data-target="#myCarousel" data-slide="prev">
                                                <div class="arrow arrow-left">
                                                    <div class="arrow-line"></div>
                                                </div>
                                                <div class="proj-nav-name">THE TEAM</div>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Close -->
                                    <div id="proj-infos-close" class="icon-wrapper-hover">
                                        <div class="icon-wrapper icon-wrapper-right" (click)="openPopUp()">
                                            <div class="icon-container"><svg id="hide-gray" class="icon icon-link-gray" viewBox="-297.1 420.9 412.1 314" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path class="aist0" d="M-188 631.4c-24.9-16.2-47.1-37.3-65.4-59.8 20.3-23.1 78.1-78.9 162.9-78.9 12.6 0 25 1.2 37 3.5l28.8-28.9c-21.1-6.2-43.2-9.6-65.8-9.6-82.9 0-158.5 46.1-206.5 113.3 22.4 32.9 51.1 62.9 83.9 85.6l25.1-25.2z"></path><path class="aist0" d="M-158 577.8c0 7.1 1.1 13.9 3 20.4l86.4-86.8c-6.3-1.9-13.1-3-20.1-3-38.3.1-69.3 31.1-69.3 69.4zM35.7 494.6l-25.4 25.5C34 533.6 55.1 551.5 72.6 572c-18.6 25.3-75.3 91-163 91-12.6 0-24.9-1.6-36.9-4.6l-28 28.1c20.8 7.4 42.6 11.5 64.9 11.5 85.8 0 161.4-53.8 205.5-127.4-21.2-30.6-48.3-56.7-79.4-76z"></path><path class="aist0" d="M-88.6 647.2c38.3 0 69.4-31.1 69.4-69.4 0-8.4-1.5-16.4-4.2-23.8l-88.8 89.1c7.4 2.7 15.3 4.1 23.6 4.1z"></path><path transform="rotate(134.862 -90.997 577.892)" class="aist0" d="M-294.5 559.8h407.1v36.1h-407.1z"></path></svg></div>
                                        </div>
                                    </div>
                                    <!-- Text -->
                                    <div class="proj-infos-txt">
                                        <div class="proj-infos-category">The Awards</div>
                                        <div class="proj-infos-title">
                                            <h4 class="proj-infos-title-l1">Awards won by NVT Design Studio</h4>
                                            <div class="proj-infos-title-bar-container">
                                                <div class="proj-infos-title-bar"></div>
                                            </div>
                                        </div>
                                        <div class="proj-infos-scrollable-wrapper" [ngStyle]="scrollObject()">
                                            <div class="proj-infos-scrollable-container">
                                                <div class="proj-infos-scrollable">
                                                    <div class="proj-infos-features">
                                                        <div class="proj-infos-feature">
                                                            <div class="proj-infos-features-l1">Times Business Awards 2019 : </div>
                                                            <div class="proj-infos-features-l2">
                                                                <p>Best Luxury Residential Interiors</p>
                                                            </div>
                                                        </div>
                                                        <div class="proj-infos-feature">
                                                            <div class="proj-infos-features-l1">Economic Times 2018 : </div>
                                                            <div class="proj-infos-features-l2">
                                                                <p>Best Lifestyle Brands </p>
                                                            </div>
                                                        </div>
                                                        <div class="proj-infos-feature">
                                                            <div class="proj-infos-features-l1">Commonfloor: </div>
                                                            <div class="proj-infos-features-l2">
                                                                <p>Best Premium Villa Interiors </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Story for Slides end -->
                        </div>
                    </div>
                    <!-- Story for slides end -->

                </div>
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end block-->

</div>
<!--end page-content-->
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    $("body").removeAttr("class");
    $("body").addClass("how-it-work");
  }

}



import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser/';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurProjectComponent } from './pages/our-Project/our-Project.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogSecondComponent } from './pages/blog-second/blog-second.component';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
 

  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent},
  { path: 'our-projects', component: OurProjectComponent},
  { path: 'our-projects/:slug', component: ProjectDetailsComponent},
  { path: 'how-it-works', component: HowItWorksComponent},
  { path: 'blog/5-tips-for-a-great-living-room', component: BlogComponent},
  { path: 'blog/the-finishing-school', component: BlogSecondComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'about-us', component: AboutUsComponent},

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule,
    BrowserModule],
    exports: [RouterModule]
})
export class AppRoutingModule {}

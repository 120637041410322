import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
declare var $: any;
/**
 * @title Basic table
 */
@Component({
 selector: 'app-our-project',
  templateUrl: './our-Project.component.html',
  styleUrls: ['./our-Project.component.scss']
})
export class OurProjectComponent implements OnInit {

  projects=[];

  constructor(
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private route: Router

  ) {}


   ngOnInit() {
     
    $("body").removeAttr("class");
    this.getProjects();
  }

  getProjects() {
    this.apiService.getAllProject().subscribe(
        list => {
          this.projects = list;
        },

      );
  }

  onCreate() {
      this.route.navigate(['/customers/create']);
  }

}

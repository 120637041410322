<div>
    <div class="background-img"></div>
    <div class="container">
        <div class="row blog">
            <div class="col-sm-offset-1 col-sm-8">
                <div class="proj-nav-wrapper">
                    <div class="proj-nav">
                        <a [routerLink]="['/blog/the-finishing-school']" class="proj-nav-link proj-nav-prev arrow-wrapper">
                            <div class="arrow arrow-left">
                                <div class="arrow-line"></div>
                            </div>
                            <div class="proj-nav-name">The Finishing School
                            </div>
                        </a>
                        <a [routerLink]="['/blog/the-finishing-school']" class="proj-nav-link proj-nav-next arrow-wrapper">
                            <div class="arrow arrow-right">
                                <div class="arrow-line"></div>
                            </div>
                            <div class="proj-nav-name">The Finishing School
                            </div>
                        </a>
                    </div>
                </div>

                <div class="card-blog">
                    <div class="blog-title-bar-container">
                        <div class="blog-title-bar"></div>
                    </div>
                    <h1>5 Tips for a Great Living Room</h1>
                    <p>Did you know that the living room was known in the 19th century as a ‘parlour’? The word has origins in the French parle, which means, ‘to speak’. It was meant to be a room where you would receive guests, and speak with them. As the
                        20th century rolled around, the term ‘living room’ became more commonly used to describe a space where the people would perform most of their living activities – such as gathering, celebrating or enjoying each other’s company.</p>
                    <p>“The modern living room wears many hats”, says Shikha, Head of Design at NVT Design Studio. “It could be a space for entertaining friends at a house party, a family movie night or a space to unwind after a long day and many more. But
                        it most certainly is a space that clearly brings alive the personality of those who call the space home.” We asked her about tips to bring alive a great living room.
                    </p>
                    <h3>Top 5 questions to ask yourself (and your designer) during Living Room design:
                    </h3>
                    <ul>
                        <li>What are we going to use the living room for?</li>
                        <li>What is my colour palette going to look like?
                        </li>
                        <li>What kind of sofa set appeals to me?</li>
                        <li>What kind of lighting am I comfortable with?</li>
                        <li>What is the list of accessories that tell my story?</li>

                    </ul>

                    <h3>Start with what ‘living’ means for you, not what you’re already used to. </h3>
                    <p>
                        “Start by taking a step back”, says Shikha. “Before designing a living room, make a list of what living means to you. Ask yourself what you see yourself using the space for.” It is likely that your living room would host all sorts of activities, from
                        cocktail parties and movie nights to Facebook Live chats with extended family. Shikha recommends planning for real life - does it mean that you have a media center or home theatre? Is it a place where you choose to work and it’s
                        all about laptops or tablets being used? How many people are going to need seats? These questions will help you stay clear of what you already have and instead understand what you would be using the space for in your new home.
                        Only then comes the layout and furniture plan that takes the homeowners’ lifestyle into account.
                    </p>
                    <h3>Letting Colours Add Character</h3>
                    <p>To make a welcoming living room that expresses your personality, Shikha recommends choosing wall-coverings or treatments that reflect your style. "Traditionally, living room walls receive more elaborate or formal treatment when compared
                        to other rooms because this room meant for the public”, she says. There are several choices when it comes to woodwork and furniture finishes that are of important consideration. Veneer finishing, concealed handles, mirror work
                        for depth, vertical lines for interconnectivity of spaces and false ceiling detailing are some of them. </p>
                    <p>Shikha recommends bringing together textures of different materials, such as wood, metal, wool, silk, and glass. "But ultimately what's important is how the materials feel to your touch", she says. No matter how exclusive a material
                        is, or how expensive, what matters is how your senses respond to it. Does it feel soft when it is meant to be? Does it feel strong when it is meant to be? These are the cues to look for when choosing materials for the living room.
                        Another easy way to incorporate texture is via throw blankets and accessories.</p>
                    <h3>Synchronizing with the Sofa </h3>
                    <p>The first piece of furniture that Shikha recommends for the living room is the sofa. "It’s important to arrive at a piece that’s going to stand the test of time and be the centerpiece of the room," she says. Her clients take their
                        time with the sofa, evaluating materials, price ranges and designs before they make a choice. The rest of the furniture of the living room flows out from the choice of sofa. </p>
                    <p> "Your lifestyle is a major point of consideration", she adds. "It will determine how much regular or rough use the sofa will undergo." It is critical to choose the best color family and fabric for this reality. For example, a mohair
                        or felt fabric is no good for a family with boisterous children or pets with muddy paws. When choosing the fabric, the "rub" number is a good way to measure its durability. It provides a clue to durability. It is determined by
                        a machine that runs back and forth over the fabric until it is worn down. 20,000 or higher rubs is a great place to start for residential upholstery. Finally, the clients’ aesthetic preference between classic and modern designs
                        plays a role and "sit tests" are the best way to gauge comfort.</p>
                    <h3>Punctuate with lighting</h3>
                    <p>
                        "Lighting plays the role of punctuation in interior design", begins Shikha. "The choice of natural light, concealed light and the kind of lamps become the commas, periods and apostrophes of life in the living room". When planning the room’s furniture
                        layout and window treatments, Shikha favours natural light over artificial. There also needs to be interplay between high and low lighting in the living room. Concealed lighting also tends to add character to a living room. Shikha
                        views the choice of lamp for its overall aesthetics, as it has sculptural echoes for a room's character. "I always talk to my clients about looking at beautiful lamps as an investment, something that will be forever in your collection,"
                        she says.
                    </p>

                    <h3>
                        Accessorize for Personality
                    </h3>
                    <p>If you think accessories are mere trinkets, showpieces or clutter, think again. "They each are an opportunity to tell a story about the personality and life of the homeowner", says Shikha. "By weaving these pieces into the overall
                        design, the space will truly feel like your home". But every piece needs to have a story or a purpose in the design of the living room. That's why curios from travel, awards or recognitions, photographs that remind you of a wonderful
                        time are all part of this personality your living room needs. "But instead of all these leading to clutter, they need to subscribe to a sense of tidiness", she says. The living room is a space for stories, and each accessory could
                        either be a chapter, a sentence, a phrase or a footnote in that story. It is a distillation of the best of who you are, projecting that image to those who you choose to welcome into your home. </p>
                    <p>In her own house, Shikha prefers art and artists to tell her own story. "I find art inspires me to unlock the aesthetic sense in my client's minds" she says. "Whether your pieces are gifts from loved ones or reminders of the places
                        that you’ve been, they are expressions of who you are and what you love."</p>
                    <p>That's the key to a wonderful living room, one that also becomes a room for living with love. </p>
                </div>

            </div>
        </div>
    </div>
</div>

import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { Observable } from 'rxjs';
import { HttpClientHelperService } from '../../core/Http/http-client-helper.service';
import { CitGlobalContantsService } from '../../core/globalUrl/cit-global-contants.service';

// @Directive()
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(
    private httpClient: HttpClientHelperService,
    private globalConst: CitGlobalContantsService
  ) {}

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  homeScroll() {
    this.change.emit();
  }

  getAllProject(): Observable<any> {
    return this.httpClient.getRequest(
      this.globalConst.allProject
    );
   }

  contact(data: any): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.contact, data);
  }
  

  getProjectDetail(slug: any): Observable<any> {
    return this.httpClient.getRequest(
      this.globalConst.projectDetail + '?slug=' + slug,
      {}
    );
  }
 

}

import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    $("body").removeAttr("class");
  }

}

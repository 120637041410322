<div class="main-container">
    <div class="outer-wrapper" id="outer-wrapper">
        <div class="side-menu" id="menu">
            <div class="sub-icons sub-icons-black" (click)="openToggle()"></div>
            <div class="sub-close" (click)="openToggle()"></div>
            <a routerLink="/our-projects" class="project-btn">Our Projects</a>
        </div>

        <!-- wrapper Container Start -->
        <div class="page-wrapper">
            <!-- <div class="navigation-off-canvas"> -->
            <!-- <div class="project-list">
                    <div class="project-nav-btn" (click)="openProjectToggle()">
                        Projects
                    </div>
                </div> -->
            <!-- <ul [style.height]="windowHeight">
                    <li *ngFor="let project of projects" [routerLink]="'our-projects/' + project?.slug" (click)="openProjectToggle()">
                        <h4>{{project?.title}}</h4>
                        <img [src]="project?.imageUrl" [alt]="project?.imageAlt">
                    </li>
                </ul> -->
            <!-- </div> -->

            <div class="navigation-project">
                <figure class="side-logo">
                    <img src="assets/img/logo-white.png" alt="logo" />
                </figure>
                <!-- <h2 class="side-menu-title">Design Studio</h2> -->
                <div class="separator"></div>
                <ul class="routerLink">
                    <li><a [routerLink]="['/home']" routerLinkActive="active" (click)="openToggle()">Home</a></li>
                    <li><a [routerLink]="['about-us']" routerLinkActive="active" (click)="openToggle()">About Us</a></li>
                    <li><a [routerLink]="['our-projects']" routerLinkActive="active" (click)="openToggle()">Our Projects</a></li>
                    <li><a [routerLink]="['/how-it-works']" routerLinkActive="active" (click)="openToggle()">How It Works</a></li>
                    <li><a [routerLink]="['/blog/5-tips-for-a-great-living-room']" routerLinkActive="active" (click)="openToggle()">Blog</a></li>
                    <li><a [routerLink]="['contact-us']" routerLinkActive="active" (click)="openToggle()">Contact Us</a></li>
                    <li><a href="javascript:void(0)" data-toggle="modal" data-target="#meetDesigner">Meet Our Designer</a></li>
                </ul>
            </div>

            <app-header></app-header>

            <router-outlet></router-outlet>
            <!-- Main Footer Start -->
            <app-footer id="page-footer" class="section"></app-footer>
            <!-- Main Footer End -->
        </div>
        <!-- wrapper Container End -->
    </div>
    <div class="right-menu">
        <a routerLink="/" class="logo">
            <div class="images">
                <img src="assets/img/logo-black.png" alt="" width="70">
            </div>
        </a>
        <div class="getTouch">
            <img src="assets/img/meet-our.png" alt="" width="90" data-toggle="modal" data-target="#meetDesigner">
            <!-- <a href="javascript:void(0)" (click)="openProjectToggle()" *ngIf="isProject"> PROJECTS</a> -->
            <!-- <a href="javascript:void(0)" data-toggle="modal" data-target="#meetDesigner" *ngIf="!isProject">Meet Our Designer</a> -->
        </div>
        <div class="arrow-icons" *ngIf="homeBtn" (click)="clickScroll()"></div>
        <div class="arrow-icons" *ngIf="!homeBtn" (click)="scrollDown()"></div>

    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="meetDesigner" tabindex="-1" role="dialog" aria-labelledby="meetDesignerLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <div class="modal-body row">
                <div class="col-sm-4">
                    <figure>
                        <img src="https://res.cloudinary.com/gurutu/image/upload/s--Pu3vLM93--/c_thumb,h_3600,w_2400/v1586678593/NVT/generic/apartment-bed-bedroom-chair-271624.jpg" width="100%" height="402px" alt="gettouch">
                    </figure>
                </div>
                <div class="col-sm-8 form-content">
                    <h2 class="modal-title" id="meetDesignerLabel">Meet Our Designer</h2>

                    <p>Feel free to drop us a line below!</p>
                    <form [formGroup]="getTouch" id="form-contact" name="submit-to-google-sheet" class="labels-uppercase clearfix" (ngSubmit)="onSubmit()">
                        <input type="hidden" name="DataSource" id="" value="Website">
                        <input type="hidden" name="url" id="" value="https://nvtdesignstudio.com/">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="name" name="Name" formControlName="name" placeholder="Name" required="">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Full Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" id="number" name="Phone" formControlName="phone" maxlength="10" placeholder="Phone Number" required="">
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" name="Email" formControlName="email" placeholder="Email" required="">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <textarea class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" id="msg" name="Message" rows="8" formControlName="message" required="" placeholder="Type your message here"></textarea>
                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required">Message is required</div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <br>
                                <div id="form-status" class="pull-left mt-3"></div>
                                <div class="form-group pull-left">
                                    <button type="button" class="btn btn-secondary cancel ml-3" data-dismiss="modal">Cancel</button>
                                    <button type="submit" [disabled]="loading" class="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-success success" data-alert="alert" [hidden]="hidden">Submitted Successfully</div>
                    <div class="error error-success error" data-alert="alert" [hidden]="error">Please Try Again</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
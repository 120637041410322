import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { NgwWowService } from "ngx-wow";
import { ApiService } from '../../core/services/api.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {

  @ViewChild("fullpageRef", { static: false }) fp_directive: ElementRef;
  @ViewChild("videoFirst", { static: false }) videoFirst: ElementRef;
  @ViewChild("videoSecond", { static: false }) videoSecond: ElementRef;
  @ViewChild("videoThird", { static: false }) videoThird: ElementRef;

  toggle:boolean = true;

  title = "NVT Design Studio";
  metaData = "Home meta";

  windowHeight:string;
  windowHeightmulti:string;

  transform: string;
  styleList = [];

  config;
  activePage;
  fullpage_api;
  show:boolean = false;
  constructor(
    private titleService: Title,
    private router: Router,
    private apiService: ApiService,
    private metaService: Meta,
    private wowService: NgwWowService
  ) {

  
    // this.styleList = ['0', $(window).height() , $(window).height() * 2]
   
    this.config = {
      anchors: ['page1', 'page2', 'page3', 'page4','page5','page6'],
      licenseKey: "YOUR LICENSE KEY HERE",
      menu: "#menu",
      slidesNavigation: true,
      lockAnchors: true,
      scrollingSpeed: 1000,
      responsiveWidth: 320,
      navigation: false,
      scrollBar: false,
      // events callback
      afterLoad: (origin, destination, direction) => {
     
      },
      afterRender: () => {
        this.wowService.init();
      },
      afterResize: (width, height) => {
        // console.log('afterResize' + width + ' ' + height);
      },
      afterSlideLoad: (section, origin, destination, direction) => {},
      onLeave: (origin, destination, direction) => {
        this.show = false;
        switch (destination.index) {
          case 1:
            this.second();
            $('#section2 .is-animated').addClass('animated fadeInRight').css('animation-delay', '.3s');
            this.activePage = 2;
            break;
          case 2:
            $('#section3 .is-animated').addClass('animated fadeInRight').css('animation-delay', '.3s');
            this.activePage = 3;
            break;
          case 3:
            this.third();
            this.show = true;
            $('#section4 .is-animated').addClass('animated fadeInUp').css('animation-delay', '.3s');
            this.activePage = 4;
            break;
          case 4:
            $('#section5 .is-animated').addClass('animated fadeInRight').css('animation-delay', '.3s');
            this.activePage = 5;
            break;
          case 5:
            this.first();
            this.activePage = 6;
            $('#section6 .is-animated').addClass('animated fadeInRight').css('animation-delay', '.3s');
            break;
            
        }
      },
    };
  }

  ngOnInit() {

    $("body").removeAttr("class");
    $("body").addClass("home");
    this.activePage = 1;
    this.windowHeight = $(window).height() + 'px';
    this.windowHeightmulti = $(window).height() * 3 + 'px'; 
    this.styleList = [ $(window).height(),'0',]

    this.apiService.change.subscribe(() => 
      {
        
        console.log(this.activePage);
    
        switch (this.activePage) {
            case 1:
              this.fullpage_api.moveTo('page2' , 1);
              this.activePage = 2;
              break;
            case 2:
              this.fullpage_api.moveTo('page3' , 1);
              this.activePage = 3;
              break;
            case 3:
              this.fullpage_api.moveTo('page4' , 1);
              this.activePage = 4;
              this.show = true;
              break;
            case 4:
              this.fullpage_api.moveTo('page5' , 1);
              this.activePage = 5 ;
              break;
            case 5:
              this.fullpage_api.moveTo('page6' , 1);
              this.activePage = 6 ;
            break;
        }
       
      });
     
   
    setInterval(() => {
      this.setBackground();
     
    }, 3000);

    this.first();
    this.second();
    this.third();
    this.titleService.setTitle(this.title);
    this.metaService.addTag({ name: "author", content: "nvt" });

    
  }

  setBackground() {
    let currentStyle =  this.styleList[Math.floor(Math.random() * this.styleList.length)];
    this.transform = currentStyle;

    // console.log(currentStyle);
}

  first() {
    $('#banner1Vid').trigger('play');
    $('#banner2Vid').trigger('pause');
    $('#banner3Vid').trigger('pause');
  }
  second() {
    $('#banner1Vid').trigger('pause');
    $('#banner2Vid').trigger('play');
    $('#banner3Vid').trigger('pause');
  }

  third() {
    $('#banner1Vid').trigger('pause');
    $('#banner2Vid').trigger('pause');
    $('#banner3Vid').trigger('play');
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  
  openToggle(){
      if(this.toggle == true){
           $('#outer-wrapper').addClass('project-nav');
           $('.sub-close').css('display','block');
          //  $("body").css('position', 'fixed');
           this.toggle = false;
      }else{
         $('#outer-wrapper').removeClass('project-nav');
         $('.sub-close').css('display','none');
        //  $("body").css('position', 'relative');
         this.toggle = true;
      }
  }

  blogNav(){
    this.router.navigate(['/blog/5-tips-for-a-great-living-room']);
  }

  superNav(){
    this.router.navigate(['/blog/the-finishing-school']);
  }


}

import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  toggle:boolean = true;
  projectToggle:boolean = true;
  projects:any;
  windowHeight:string;

  constructor(
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private route: Router
    ) {

      
     }

  ngOnInit() {
    this.windowHeight = $(window).height() + 'px';
     this.getProjects();
  }

   getProjects() {
    this.apiService.getAllProject().subscribe(
        list => {
          this.projects = list;
        },
      );
  }

  openProjectToggle(){
	if(this.projectToggle == true){
  		 $('#outer-wrapper').addClass('show-nav');
  		 $('.page-wrapper').css('position','fixed');
  		 this.projectToggle = false;
	}else{
     $('#outer-wrapper').removeClass('show-nav');
     $('.page-wrapper').css('position','relative');
		 this.projectToggle = true;
	}
  } 

  openToggle(){

    if(this.toggle == true){
         $('#outer-wrapper').addClass('project-nav');
         this.toggle = false;
    }else{
       $('#outer-wrapper').removeClass('project-nav');
       this.toggle = true;
    }
  }

  closeProject(){
    //  $('#outer-wrapper').removeClass('pshow-nav');
    //    this.toggle = true;
  } 

  menuProject(){
    //   $('#outer-wrapper').removeClass('show-nav');
    //  this.projectToggle = true;
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CitGlobalContantsService {

  // baseUrl
  baseUrl = 'http://api.nvtdesignstudio.com/';

 
  allProject: string = this.baseUrl + 'projects/get/all';

  projectDetail: string = this.baseUrl + 'projects/get/detail';

  contact: string = 'https://script.google.com/macros/s/AKfycbx-kGSgfCJ076tNcCVgXHCHskNWMZQWq7BxYDTX1YVX9K33y42F/exec';




}





import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  isClosed:boolean = false;
  windowHeights:number;
  windowHeight:string;
  windowMargin:string;
  scrollHeight:number;
  
  styleObject(): Object {
    return {'height': this.windowHeight , 'margin-top': this.windowMargin}
  }
  
  scrollObject(): Object {
    if(this.windowHeights >= 400){
      return {'height': this.scrollHeight + 'px'}
    }else{
      return {'height': '400px'}
    }
  }

    
  scrollObjectFounder(): Object {
    if(this.windowHeights >= 400){
      return {'height': this.scrollHeight + 70 + 'px'}
    }else{
      return {'height': '400px'}
    }
  }
  
  

  constructor() {}

  ngOnInit() {
    $("body").removeAttr("class");
    $("body").addClass("about-us");
    this.windowHeights = $(window).height() - 180;
    this.scrollHeight = this.windowHeights - 280;
    this.windowMargin = '-' + this.windowHeights / 2 + 'px';
    this.windowHeight =  this.windowHeights + 'px';


    $('.num').html('1');

    $('#myCarousel').bind('wheel', function(event){

        if(event.originalEvent.deltaY < 0) {
          
            $(this).carousel('prev');
        }
        else{
          $(this).carousel('next');
        }
    });

    $('.proj-infos').on('mouseenter', function(event) {
      $( '#myCarousel' ).unbind( "wheel" );
    });

    $('.proj-infos').on('mouseleave', function(event) {
          $('#myCarousel').bind('wheel', function(events){
            if(events.originalEvent.deltaY < 0) {
                $(this).carousel('prev');
            }
            else{
              $(this).carousel('next');
            }
        });
    });

    //mobile touch
   
    var ts;
    $('#myCarousel').bind('touchstart', function(event){
       ts = event.originalEvent.touches[0].clientY;
    });

    $('#myCarousel').bind('touchend', function (e){
        var te = e.originalEvent.changedTouches[0].clientY;
        if(ts > te+5){
          $(this).carousel('next');
        }else if(ts < te-5){
          $(this).carousel('prev');
        }
    });


    $('.proj-infos-txt').on('touchstart', function(e) {  
      // alert('ouside touch');  
      e.stopPropagation(); //stops propagation
      if(e.type == "touchstart") {
        $( '#myCarousel' ).unbind( "touchstart" );
        $( '#myCarousel' ).unbind( "touchend" );
      } 
  });
  
  //outside div touch
  $('.carousel-inner').on('touchstart', function(e) {    
    // alert('ouside touch');
      if(e.type == "touchstart") {
        $( '#myCarousel' ).bind( "touchstart" );
        $( '#myCarousel' ).bind( "touchend" );
        $('#myCarousel').bind('touchstart', function(event){
          ts = event.originalEvent.touches[0].clientY;
       });
   
       $('#myCarousel').bind('touchend', function (e){
           var te = e.originalEvent.changedTouches[0].clientY;
           if(ts > te+5){
             $(this).carousel('next');
           }else if(ts < te-5){
             $(this).carousel('prev');
           }
       });
      } 
  });
  
  }


  

  openPopUp(){
    this.isClosed = !this.isClosed;
  }

 

}

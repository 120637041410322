 <div>
        <div class="header-background bg-transfer overlay bg-fixed" style="background-image: url('https://res.cloudinary.com/gurutu/image/upload/v1556046052/Nestbox/commercial.jpg')">
            <div class="container">
                <div class="title">
                    <h1>404 Page</h1>
                </div>
            </div>
            <img src="https://res.cloudinary.com/gurutu/image/upload/v1556046052/Nestbox/commercial.jpg" alt="">
        </div>
        <!--end header-background-->
            <div class="container">
                <div class="row ">
                  <div id="notfound">
						<div class="notfound">
							<div class="notfound-404">
								<h1>Oops!</h1>
							</div>
							<h2>404 - Page not found</h2>
							<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
							<div routerLink="/">
								<button class="button shadow-radial" data-wow-delay="0.3s" >GO TO HOME</button>
							</div>
						</div>
					</div>
                </div>
                <!--end row-->
            </div>
        <!--end block-->

    </div>
    <!--end page-content-->





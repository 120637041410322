
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class HttpClientHelperService {

    constructor(private http: HttpClient) {

    }

    // HTTP-GET
    getRequest<T>(url: string, queryParams?: any, withAuthToken: boolean = true, defaultValue: T = null): Observable<any> {



        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: null
        };

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }

        if (queryParams != null) {
            let httpParams = new HttpParams();
            Object.keys(queryParams).forEach(function(key) {
                httpParams = httpParams.append(key, queryParams[key]);
            });
            options.params = queryParams;
        }

        return this.http.get<T>(url, options)
            .pipe(
            tap(result => this.log(`HTTP-GET|${url}`, result)),
            catchError(this.handleError(`HTTP-GET|${url}`, defaultValue))
            );
    }

    // HTTP-GET
    getXMLRequest(url: string, queryParams?: HttpParams, withAuthToken: boolean = true, defaultValue: any = null): Observable<any> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'text/xml' }),
            params: null,
            responseType: 'text' as 'text'
        };

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }

        if (queryParams != null) {
            options.params = queryParams;
        }

        return this.http.get(url, options)
            .pipe(
                tap(result => this.log(`HTTP-GET|${url}`, result)),
                catchError(this.handleError(`HTTP-GET|${url}`, defaultValue))
            );
    }

    // HTTP-POST
    postJSONRequest<T>(url: string, body: any, withAuthToken: boolean = true, defaultValue: T = null): Observable<any> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: null
        };

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }

        return this.http.post<T>(url, body, options)
            .pipe(
            tap(result => this.log(`HTTP-GET|${url}`, result)),
            catchError(this.handleError(`HTTP-GET|${url}`, defaultValue))
            );
    }

    // HTTP-POST
    postRequest<T>(url: string, body: any, headers?: HttpHeaders, withAuthToken: boolean = true, defaultValue: T = null): Observable<any> {

        const options = {
            headers: null
        };

        if (headers != null) {
            options.headers = headers;
        } else {
            options.headers = new HttpHeaders();
        }

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }

        return this.http.post<T>(url, body, options)
            .pipe(
            tap(result => this.log(`HTTP-POST|${url}`, result)),
            catchError(this.handleError(`HTTP-POST|${url}`, defaultValue))
            );
    }

    // HTTP-PUT
    putRequest<T>(url: string, body: any, queryParams?: HttpParams, withAuthToken: boolean = true, defaultValue: T = null): Observable<any> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: null
        };

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }


        if (queryParams != null) {
            options.params = queryParams;
        }

        return this.http.put<T>(url, body, options)
            .pipe(
            tap(result => this.log(`HTTP-GET|${url}`, result)),
            catchError(this.handleError(`HTTP-GET|${url}`, defaultValue))
            );
    }

    // HTTP-DELETE
    deleteRequest(url: string, body: any, withAuthToken: boolean = true): Observable<object> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: null
        };

        if (withAuthToken == false) {
            options.headers = options.headers.append('No-Authorization', '');
        }

        return this.http.delete(url, options)
            .pipe(
            tap(result => this.log(`HTTP-GET|${url}`, result)),
            catchError(this.handleError(`HTTP-GET|${url}`, null))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }

    private log(message: string, data: any = null) {
        // console.log(`Message Log:: ${message} ::`, data);
    }
}

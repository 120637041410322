import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    $("body").removeAttr("class");
    $("body").addClass("blog");
  }

}

import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-blog-second',
  templateUrl: './blog-second.component.html',
  styleUrls: ['./blog-second.component.scss']
})
export class BlogSecondComponent implements OnInit {


  constructor() {}

  ngOnInit() {
    $("body").removeAttr("class");
    $("body").addClass("blog");
  }

}

<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)" #fullpageRef>

    <!-- First section Image1 -->
    <div class="section" id="section1">

        <div class="home-carousel" [style.height]="windowHeight">
            <div class="left">
                <ul [style.height]="windowHeightmulti" [style.transform]="'translate3d(0px, -' + transform  + 'px, 0px)'">
                    <li class="firstImg" [style.height]="windowHeight">
                    </li>
                    <li class="secondImg" [style.height]="windowHeight">
                    </li>
                    <li class="thirdImg" [style.height]="windowHeight"></li>
                </ul>
            </div>
            <div class="right">
                <ul [style.height]="windowHeightmulti" [style.transform]="'translate3d(-50%, ' + transform  + 'px, 0px)'">
                    <li class="thirdImg" [style.height]="windowHeight"></li>
                    <li class="secondImg" [style.height]="windowHeight">
                    </li>
                    <li class="firstImg" [style.height]="windowHeight">
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Fourth section Video2 -->
    <div class="section" id="section4">
        <div class="bg-background"></div>
        <video id="banner2Vid" playsinline autoplay muted="muted" loop #videoSecond>
            <source src="./assets/video/second.mp4" type="video/mp4">
        </video>

        <div class="container flex talk">
            <div class="center-side">
                <h1 class="is-animated" data-wow-delay="0.3s">My home talks to me everyday
                </h1>
                <p class="is-animated" data-wow-delay="0.3s">
                    <a [routerLink]="['/our-projects/rupali-center-zen']">NVT presents Rupali's home</a>
                </p>
            </div>
        </div>

    </div>

    <!-- Third section Image2 -->
    <div class="section" id="section3">
        <div class="bg-background"></div>
        <div class="container flex conversation">
            <div class="center-side">
                <h1 class="is-animated" data-wow-delay="0.3s">Where the conversation begins
                </h1>
                <p class="is-animated" data-wow-delay="0.3s">
                    <a [routerLink]="['/our-projects/where-conversation-begins']">NVT presents Arushi's Home</a>
                </p>
            </div>
        </div>
    </div>

    <!-- Sixth section Video3 -->
    <div class="section" id="section6">
        <div class="bg-background"></div>
        <video id="banner3Vid" playsinline autoplay muted="muted" loop #videoThird>
        <source src="./assets/video/third.mp4" type="video/mp4">
        </video>

        <div class="container flex super">
            <div class="center-side">
                <h1 class="is-animated" data-wow-delay="0.3s">Superior workmanship</h1>
                <button class="is-animated button button--nuka button--round-l button--inverted button--text-thick button--text-upper button--size-s" data-wow-delay="0.3s" (click)="superNav()">LEARN MORE</button>
            </div>
        </div>


        <div class="layout-count" *ngIf="show">
            <div class="list">
                <div class="title">
                </div>
                <div class="content">
                    <img src="./assets/img/memorable.png" alt="memorable">
                    <div class="mb-2">
                        Memorable experience
                    </div>
                </div>
            </div>
            <div class="list">
                <div class="title">
                </div>
                <div class="content">
                    <img src="./assets/img/exclusive-design.png" alt="memorable">
                    <div class="mb-2">
                        Exclusive designs
                    </div>
                </div>
            </div>
            <div class="list">
                <div class="title">
                </div>
                <div class="content">
                    <img src="./assets/img/build-last.png" alt="memorable">
                    <div class="mb-2">
                        Built to lasts
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FIfth section Image3 -->
    <div class="section" id="section5">
        <div class="bg-background"></div>
        <div class="container flex story">
            <div class="center-side">
                <h1 class="is-animated" data-wow-delay="0.3s">The Story Of Aarav's Cook Book Nook
                </h1>
                <p class="is-animated" data-wow-delay="0.3s">
                    <a [routerLink]="['/our-projects/aarav-cosy-book-nook']">NVT presents Aarav's Home</a>
                </p>
            </div>
        </div>
    </div>

    <!-- Second section Video1 -->
    <div class="section" id="section2">
        <div class="bg-background"></div>
        <video id="banner1Vid" playsinline autoplay muted="muted" loop #videoFirst>
            <source src="./assets/video/first.mp4" type="video/mp4">
        </video>

        <div class="container flex tips">
            <div class="center-side">
                <h1 class="is-animated" data-wow-delay="0.3s">5 tips for your living room
                </h1>

                <button class="is-animated button button--nuka button--round-l button--inverted button--text-thick button--text-upper button--size-s" data-wow-delay="0.3s" (click)="blogNav()">Get It</button>
            </div>
        </div>

    </div>


</div>
<div>
    <div class="header-background bg-transfer overlay bg-fixed" [ngStyle]="{'background-image': 'url(' + project?.bannerImageUrl + ')' }">
        <img [src]="project?.bannerImageUrl" [alt]="project?.bannerImageUrl">
    </div>
    <!--end header-background-->

    <div class="work-detail">
        <div class="container">
            <div class="row">
                <div class="col-md-4 similar-section">
                    <div class="left">

                        <div class="title framed shadow">
                            <h2>{{project?.title}}</h2>
                            <div class="separator"></div>
                        </div>

                        <div class="separator"></div>
                        <div class="wrapper" [class.show]="show" #getHeight id="height">
                            <div class="wrapper-list" [ngStyle]="{'height': contentHeight  > 1048 ? '1048px' : '' }">


                                <div *ngFor="let desc of project?.description?.body" style="margin-bottom:2em">
                                    <div class="tags">
                                        <span class="tag">{{desc.title}}</span>
                                    </div>
                                    <p>{{desc.text}}</p>
                                </div>
                                <h2>{{project?.description?.heading}}</h2>
                                <ul>
                                    <li *ngFor="let list of project?.description?.sub_points">
                                        <p> {{list.point}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button class="showMore" *ngIf="contentHeight > 1048" (click)="clickShow('show')">{{ show ? 'Show Less': 'Show More' }}</button>
                        <button class="showMore" *ngIf="contentHeight == '850'" (click)="clickShow('less')">Show Less</button>

                        <div class="separator"></div>
                        <a routerLink="/contact-us" class="arrow icon similar">Want similar? Contact us! &nbsp;
                           <span  class="arrow-icons"></span>
                        </a>
                    </div>
                    <!--end left-->
                </div>


                <!--end col-md-4-->
                <div class="col-md-8">
                    <div class="right">
                        <div class="page-switcher top-arrow">
                            <a [routerLink]="'/our-projects/' +project?.prev" class="prev" title="prev project"></a>
                            <a [routerLink]="'/our-projects'" class="menu-center" title="Our projects"></a>
                            <a [routerLink]="'/our-projects/' +project?.next" class="next" title="next project"></a>
                        </div>
                        <!--end page-switcher -->
                        <div class="gallery">
                            <div class="gallery-row details-project gallery-fullscreen" gallerize>
                                <img *ngFor="let image of project?.images; index as i" [src]="image?.thumbImageUrl" [attr.imageSrc]="image?.imageUrl" [attr.thumbSrc]="image?.thumbImageUrl" class="wow fadeInDown image{{i}}" data-wow-delay="0.3s">
                            </div>
                        </div>
                        <div class="page-switcher">
                            <a [routerLink]="'/our-projects/' +project?.prev" class="prev" title="prev project"></a>
                            <a [routerLink]="'/our-projects'" class="menu-center" title="Our projects"></a>
                            <a [routerLink]="'/our-projects/' +project?.next" class="next" title="next project"></a>
                        </div>
                        <!--end gallery-->
                    </div>
                    <!--end right-->
                </div>
                <!--end col-md-8-->
            </div>
        </div>
        <!--end container-->
    </div>
    <!--end block-->

    <hr>



    <div class="block">
        <div class="container video-section">
            <div class="row gallery">
                <div class="section-title">
                    <h2>Video Walkthrough</h2>
                </div>
                <div class="col-md-4 col-sm-4">
                    <a class="gallery-item" data-scroll-reveal="enter left and move 50px">
                        <div class="title framed shadow">
                            <div class="name">NVT Arcot Vaksana</div>
                            <div class="place">Sarjapura Road, Bangalore</div>
                        </div>
                        <!--end title-->


                        <div class="image" (click)="openModal('custom-modal-1')">
                            <img src="/assets/img/play.svg" width="50" class="play">
                            <img src="/assets/img/video-thumb/first.png" width="100%">
                        </div>
                        <!--end image-->
                    </a>
                    <!--end gallery-item-->
                </div>
                <div class="col-md-4 col-sm-4">
                    <a class="gallery-item" data-scroll-reveal="enter left and move 50px">
                        <div class="title framed shadow">
                            <div class="name">NVT Mystic Garden</div>
                            <div class="place">Sarjapura Road, Bangalore</div>
                        </div>
                        <!--end title-->

                        <div class="image" (click)="openModal('custom-modal-2')">
                            <img src="/assets/img/play.svg" width="50" class="play">

                            <img src="/assets/img/video-thumb/second.png" width="100%">
                        </div>
                        <!--end image-->
                    </a>
                    <!--end gallery-item-->
                </div>
                <div class="col-md-4 col-sm-4">
                    <a class="gallery-item" data-scroll-reveal="enter left and move 50px">
                        <div class="title framed shadow">
                            <div class="name">NVT Life Square</div>
                            <div class="place">Whitefield, Bangalore</div>
                        </div>
                        <!--end title-->


                        <div class="image" (click)="openModal('custom-modal-3')">
                            <img src="/assets/img/play.svg" width="50" class="play">

                            <img src="/assets/img/video-thumb/third.png" width="100%">
                        </div>
                        <!--end image-->
                    </a>
                    <!--end gallery-item-->
                </div>
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end block-->
</div>
<!--end page-content-->

<app-video-popup id="custom-modal-1">

    <iframe width="100%" height="100%" id="custom-modal-11" src="https://www.youtube.com/embed/tbS4mJFspBY?t=0&rel=0&amp;&playlist=tbS4mJFspBY&amp;showinfo=0&loop=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
    <img src="assets/icon/cancel.svg" (click)="closeModal('custom-modal-1');">
</app-video-popup>

<app-video-popup id="custom-modal-2">
    <iframe width="100%" height="100%" id="custom-modal-21" src="https://www.youtube.com/embed/XgrSYo7CmSo?t=0&rel=0&amp;&playlist=XgrSYo7CmSo&amp;showinfo=0&loop=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
    <img src="assets/icon/cancel.svg" (click)="closeModal('custom-modal-2');">
</app-video-popup>

<app-video-popup id="custom-modal-3">
    <iframe width="100%" height="100%" id="custom-modal-31" src="https://www.youtube.com/embed/JnyMxtIciCc?t=0&rel=0&amp;&playlist=JnyMxtIciCc&amp;showinfo=0&loop=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
    <img src="assets/icon/cancel.svg" (click)="closeModal('custom-modal-3');">
</app-video-popup>
<div>
    <div class="header-background bg-transfer overlay" style="background-image: url('assets/img/our-project-banner.jpg')">
        <div class="container">
            <div class="title">
                <h1></h1>
            </div>
        </div>
        <img src="assets/img/our-project-banner.jpg" alt="">
    </div>



    <!--end header-background-->

    <div class="block">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 header-title">
                    <h2>"WE TELL YOUR STORY"</h2>
                    <p>Every home tells a story, but we help you tell your story better. Here is a selection of stories told through the homes we have designed for discerning clients over the years. Perhaps you will find your story with us, too.</p>
                    <div class="separator"></div>
                </div>
            </div>


            <div class="row">
                <div class="gallery">
                    <div class="row">
                        <div class="col-md-4 col-sm-6" *ngFor="let project of projects">
                            <a [routerLink]="project?.slug" class="gallery-item" data-scroll-reveal="enter left and move 50px">
                                <div class="title framed shadow">
                                    <div class="name">{{project?.title}}</div>
                                </div>
                                <!--end title-->
                                <!-- <div class="arrow arrow_right"></div> -->

                                <div class="link link--arrowed">
                                    <img src="/assets/img/arrow-icon.png" width="18">
                                </div>

                                <div class="image">
                                    <figure>
                                        <img [src]="project?.imageUrl" class="wow fadeInDown thumb" data-wow-delay="0.3s" [alt]="project?.imageAlt">
                                    </figure>
                                </div>
                                <!--end image-->
                            </a>
                            <!--end gallery-item-->
                        </div>
                    </div>
                    <!--end row-->

                </div>
                <!--end gallery-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end block-->


</div>
<!--end page-content-->